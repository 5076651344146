.optimized-image-zoom {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

.link-embedded {
  font-weight: bold;
  display: flex;
  align-items: center;
}

// .MuiButtonBase-root .MuiSvgIcon-root {
  // fill: #ffffff;
// }
